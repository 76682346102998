<div class="component-container" style="max-width: 768px; margin: auto;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <mat-progress-bar mode="indeterminate" *ngIf="(locationIsLoading$ | async)"></mat-progress-bar>
                <div class="section-container">
                  <div class="top-banner">
                    <ng-container *ngIf="(logoLocationUrl$ | async) as logoLocationUrl">
                      <img [src]="logoLocationUrl"
                           class="logo-container"
                           *ngIf="logoLocationUrl"/>
                    </ng-container>
                  </div>
                    <div class="map-section">
                      <div #map id="map" style="height: 100%;">
                        <agm-map *ngIf="selectedLocation && selectedLocation.address.latitude && selectedLocation.address.longitude"
                                 style="width:100%;height:100%;"
                                 [latitude]="selectedLocation.address.latitude"
                                 [longitude]="selectedLocation.address.longitude"
                                 [zoom]="16">
                          <agm-marker [latitude]="selectedLocation.address.latitude" 
                                      [longitude]="selectedLocation.address.longitude">
                          </agm-marker>
                        </agm-map>
                      </div>
                    </div>
                    <div class="location-address">
                        <ng-container *ngIf="selectedLocation && selectedLocation.address">
                            <div>{{selectedLocation.name}}</div>
                            <div>{{selectedLocation.address.line1}}<span *ngIf="selectedLocation.address.line1">,</span><span *ngIf="selectedLocation.address.line2">&nbsp;{{selectedLocation.address.line2}}</span> {{selectedLocation.address.city}} {{selectedLocation.address.state}} {{selectedLocation.address.zip}}</div>
                        </ng-container>
                    </div>

                    <div class="location-dropdown-selector-label">select location that best serves you</div>
                    <ng-container *ngFor="let location of locations$ | async as locations">
                        <div [ngClass]="{'location-dropdown-selector': true, 'dropdown-selected': location.id == selectedLocation.id}" (click)="selectLocation(location)">
                            {{location.name}}
                        </div> 
                    </ng-container>
                </div>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 bottomspace">

                <div class="section-container">
                    <div class="tilde-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                            id="footer-sample-full" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                            class="iconify iconify--mdi">
                            <path fill="currentColor"
                                d="M2 15s0-6 6-6c4 0 4.5 3.5 7.5 3.5c4 0 4-3.5 4-3.5H22s0 6-6 6c-4 0-5.5-3.5-7.5-3.5c-4 0-4 3.5-4 3.5H2">
                            </path>
                        </svg>
                    </div>
                    <div class="booking-info">
                        <ng-container *ngIf="(headerText$ | async) as headerText">
                            <div [innerHTML]="headerText"></div>
                        </ng-container>
                    </div>
                    <mat-progress-bar mode="indeterminate" *ngIf="blockIsLoading"></mat-progress-bar>
                    <ng-container *ngIf="selectedLocation && settingsNewPatientUrlDto && !settingsNewPatientUrlDto.disableSchedule">
                        <div class="calender-cont">
                            <div class="inline-datepicker">
                                <mat-calendar 
                                            [dateClass]="dateClass()"
                                            [selected]="selectedDate"
                                            [minDate]="minDate"
                                            [maxDate]="maxDate"
                                            (selectedChange)="onSelectDate($event)"
                                            [dateFilter]="myDateFilter"
                                            class="calendar-schedule">
                                </mat-calendar>
                            </div>
                        </div>
                        <div class="timeslot-wrapper">
                            <div class="timeslot-label" *ngIf="schedules && schedules.length > 0">Available times for this day- this office</div>
                            <div class="timeslot-label no-appointment" *ngIf="schedules && schedules.length == 0">Sorry no appointments available for this day.</div>
                            <div class="container">
                                <ng-container *ngFor="let schedule of schedules; let index = index">
                                    <ng-container *ngIf="index == 0 || index % 3 == 0">
                                        <div class="row" style="margin-bottom: 15px">
                                            <div class="col-4"
                                                 *ngIf="schedules[index]">
                                                <div class="timeslot-selector new-patient-url-button"
                                                     (click)="toggleSchedule(schedules[index])"
                                                     [ngClass]="{'disabled': isWorking || blockIsLoading || (selectedSchedule && schedules[index].id == selectedSchedule.id)}">
                                                    {{schedules[index].startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}}
                                                </div>
                                            </div>
                                            <div class="col-4"
                                                 *ngIf="schedules[index + 1]">
                                                <div class="timeslot-selector new-patient-url-button"
                                                     (click)="toggleSchedule(schedules[index + 1])"
                                                     [ngClass]="{'disabled': isWorking || blockIsLoading || (selectedSchedule && schedules[index + 1].id == selectedSchedule.id)}">
                                                    {{schedules[index + 1].startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}}
                                                </div>
                                            </div>
                                            <div class="col-4"
                                                 *ngIf="schedules[index + 2]">
                                                <div class="timeslot-selector new-patient-url-button"
                                                     (click)="toggleSchedule(schedules[index + 2])"
                                                     [ngClass]="{'disabled': isWorking || blockIsLoading || (selectedSchedule && schedules[index + 2].id == selectedSchedule.id)}">
                                                    {{schedules[index + 2].startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div *ngIf="selectedSchedule"
                             class="hold-information">
                          I'm going to hold {{selectedSchedule.startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}} on the {{selectedSchedule.startTime | momentOrdinalDate: selectedLocation?.ianaTimeZone}} while you enter your name and contact information.
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12">
                <mat-progress-bar mode="indeterminate" *ngIf="isWorking"></mat-progress-bar>
                <form [formGroup]="newPatientAppointment">
                    <div class="section-container colored-bg">
                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">Patient’s First Name</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="patientFirstName"
                                   autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">Last Name</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="patientLastName"
                                   autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">Birthdate</mat-label>
                            <input matInput
                                   [matDatepicker]="birthdate"
                                   placeholder="MM/DD/YYYY"
                                   autocomplete="off"
                                   [max]="today"
                                   formControlName="patientDOB"
                                   (dateChange)="birthDateChanged($event.value)">
                            <mat-datepicker-toggle matSuffix
                                                   [for]="birthdate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #birthdate
                                            disabled="false"
                                            [startAt]="today">
                            </mat-datepicker>
                        </mat-form-field>

                        <mat-radio-group formControlName="isPatientResponsible"
                                         name="isPatientResponsible">
                            <mat-radio-button [value]="true">
                                Patient Responsible &nbsp;&nbsp;
                            </mat-radio-button>
                            <mat-radio-button [value]="false">
                                Other
                            </mat-radio-button>
                        </mat-radio-group>

                        <ng-container *ngIf="!newPatientAppointment.value.isPatientResponsible">
                            <mat-form-field class="input-full-width">
                                <mat-label class="input-label">Relationship to patient</mat-label>
                                <mat-select formControlName="contactRelationshipType"
                                            [disableOptionCentering]="true">
                                    <mat-option *ngFor="let relationshipType of relationshipTypes"
                                                [value]="relationshipType">
                                        {{relationshipType}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="input-full-width">
                                <mat-label class="input-label">Responsible First Name</mat-label>
                                <input matInput
                                       type="text"
                                       formControlName="contactFirstName"
                                       autocomplete="off">
                            </mat-form-field>

                            <mat-form-field class="input-full-width">
                                <mat-label class="input-label">Responsible Last Name</mat-label>
                                <input matInput
                                       type="text"
                                       formControlName="contactLastName"
                                       autocomplete="off">
                            </mat-form-field>
                        </ng-container>

                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">Your Cell Number</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="mobilePhone"
                                   [textMask]="{mask: phonemask}"
                                   autocomplete="off">
                        </mat-form-field>

                        <div class="error-msg"
										*ngIf="(newPatientAppointment.controls.mobilePhone.hasError('pattern')) && ( newPatientAppointment.controls.mobilePhone.dirty || newPatientAppointment.controls.mobilePhone.touched )">
										Enter a valid 10 digit mobile number</div>

                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">Your Email Address</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="emailAddress"
                                   [textMask]="{mask: emailMask}"
                                   autocomplete="off">
                        </mat-form-field>

                        <div class="error-msg"
										*ngIf="(newPatientAppointment.controls.emailAddress.hasError('email')) && ( newPatientAppointment.controls.emailAddress.dirty || newPatientAppointment.controls.emailAddress.touched )">
										Enter a valid email id</div>

                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">How did you hear about us?</mat-label>
                            <!-- <input matInput
                                   type="text"
                                   formControlName="referringComments"
                                   autocomplete="off"> -->

                            <input matInput formControlName="referringComments" [matAutocomplete]="autoTitle" #HearAboutInput>

                            <mat-autocomplete #autoTitle="matAutocomplete">
                                <mat-option *ngFor="let hearAboutUsItem of hearAboutUsFilter"
                                    [value]="hearAboutUsItem.name">
                                    {{ hearAboutUsItem.name }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">Tell us your dental concerns:</mat-label>
                            <textarea matInput
                                      type="text"
                                      formControlName="concerns"
                                      autocomplete="off">
                            </textarea>
                        </mat-form-field>

                        <div style="margin-bottom: 10px;" *ngIf="settingsNewPatientUrlDto && settingsNewPatientUrlDto && settingsNewPatientUrlDto.disableInsurance">
                            <button mat-icon-button
                                type='button'
                                color="accent"
                                ngfSelect
                                multiple="1"
                                (filesChange)="insuranceFileChanged($event)"
                                [(files)]="insuranceFiles">
                                <mat-icon>attach_file</mat-icon>
                            </button>

                            <mat-list dense *ngIf="newPatientAppointment.get('files').value">
                                <mat-list-item *ngFor="let fileAttachment of getAttachedInsuranceFiles(); let i = index">
                                    {{fileAttachment.name}}
                                    <mat-icon color="warn"
                                                class="remove-attachment"
                                                (click)="removeAttachedInsuranceFile(i)"
                                                matTooltip="Remove Attachment">
                                        close
                                    </mat-icon>
                                </mat-list-item>
                            </mat-list>
                        </div>

                        <ng-container *ngIf="settingsNewPatientUrlDto && !settingsNewPatientUrlDto.disableSchedule">
                            <button mat-button
                                [disabled]="!newPatientAppointment.valid || !this.selectedLocation || !selectedSchedule"
                                class="appintment-btn new-patient-url-button"
                                type='button'
                                (click)="checkbeforeSubmit()">
                                Please schedule my appointment
                            </button>
                        </ng-container>

                        <ng-container *ngIf="settingsNewPatientUrlDto && settingsNewPatientUrlDto.disableSchedule">
                            <button mat-button
                                [disabled]="!newPatientAppointment.valid || !this.selectedLocation"
                                class="appintment-btn new-patient-url-button"
                                type='button'
                                (click)="checkbeforeSubmit()">
                                Click to save
                            </button>
                        </ng-container>

                        
                    </div>
                </form>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: 25px;" *ngIf="isCreatedPatient">
                <div class="section-container">
                    <div class="booking-info">
                        <div [innerHTML]="settingsNewPatientUrlDto.footerText"></div>
                    </div>

                    <div style="display: flex; justify-content: flex-end;">
                        <button mat-raised-button class="new-patient-url-button" (click)="clearForm()">CLEAR</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>